import { $fetch, $post } from '@/services/axios';

export const CUSTOMER_MESSAGE_ACTIVE_CSV_URL = `customer/messages/csv`;
export const CUSTOMER_MESSAGE_HISTORY_CSV_URL = `customer/messages/history/csv`;

export function fetch(params) {
  return $fetch(`customer/messages`, params);
}

export function history(params) {
  return $fetch(`customer/messages/history`, params);
}

export function retry(id) {
  return $post(`customer/message/${id}/retry`);
}
