<template>
  <!-- prettier-ignore -->
  <div>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <n-field-id v-model="condition.customerId" label="customerId" />
      <n-field-id v-model="condition.accountId" label="accountId" />
      <n-field-enum v-model="condition.type" enum-name="CustomerMessageType" label="type" all-option />
      <n-field-enum v-model="condition.status" enum-name="IssueStatus" label="status" all-option />
      <n-field-text v-model="condition.token" label="token" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <customer-message-table :records="records" is-active></customer-message-table>
    </n-table>
  </div>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import CustomerMessageTable from './CustomerCustomerMessageTable';
import { fetch } from '@/api/customer/customer-customer-message';

export default CustomerView.extend({
  name: 'CustomerCustomerMessageActive',
  components: { CustomerMessageTable },
  mixins: [PaginateMixin],
  props: {
    condition: Object,
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(data) {
      this.records = data.records;
      this.$emit('records', this.records);
    },
  },
});
</script>
