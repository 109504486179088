<template>
  <!-- prettier-ignore -->
  <index-content>
    <Tabs v-model="tab" name="customerMessage" :animated="false" @on-click="changeTab">
      <template slot="extra">
        <div class="n-button-box">
          <nb-download v-if="tab === 'active'" :url="CUSTOMER_MESSAGE_ACTIVE_CSV_URL" :params="activeCondition" />
          <nb-download v-if="tab === 'history'" :url="CUSTOMER_MESSAGE_HISTORY_CSV_URL" :params="historyCondition" :disabled="hisCsvDisabled" />
          <n-button ghost custom-icon="fas fa-mobile-alt" text="devices" @on-click="toDevices" />
          <n-button ghost custom-icon="fa fa-cog" text="template" @on-click="doTemplate" />
        </div>
      </template>
      <TabPane tab="customerMessage" name="active" :label="this.$t('label.active')" class="pt-2">
        <customer-customer-message-active :condition="activeCondition" @records="changeActiveRecords" />
      </TabPane>
      <TabPane tab="customerMessage" name="history" :label="this.$t('label.history')" class="pt-2">
        <customer-customer-message-history :condition="historyCondition" @records="changeHistoryRecords" @searchedHasError="v => searchedHasError = v" />
      </TabPane>
    </Tabs>
  </index-content>
</template>

<script>
import CustomerView from './view';
import { CUSTOMER_MESSAGE_ACTIVE_CSV_URL, CUSTOMER_MESSAGE_HISTORY_CSV_URL } from '@/api/customer/customer-customer-message';
import CustomerCustomerMessageActive from './CustomerCustomerMessageActive';
import CustomerCustomerMessageHistory from './CustomerCustomerMessageHistory';
import { isEmpty } from '@/helpers/utils';

export default CustomerView.extend({
  name: 'CustomerCustomerMessage',
  components: { CustomerCustomerMessageActive, CustomerCustomerMessageHistory },
  data() {
    return {
      tab: 'active',
      activeCondition: {
        companyId: null,
        customerId: null,
        accountId: null,
        type: 0,
        status: 0,
        token: null,
        from: this.sod,
        to: this.eod,
        isHistory: false,
      },
      historyCondition: {
        companyId: null,
        accountId: null,
        from: this.halfYearAgo,
        to: this.eod,
        isHistory: true,
      },
      activeRecords: [],
      historyRecords: [],
      CUSTOMER_MESSAGE_ACTIVE_CSV_URL,
      CUSTOMER_MESSAGE_HISTORY_CSV_URL,
      searchedHasError: true,
    };
  },
  computed: {
    hisCsvDisabled() {
      return isEmpty(this.historyCondition.accountId) || this.searchedHasError;
    },
  },
  methods: {
    changeActiveRecords(records) {
      this.activeRecords = records;
      if (this.tab === 'active') this.changeTab('active');
    },
    changeHistoryRecords(records) {
      this.historyRecords = records;
      if (this.tab === 'history') this.changeTab('history');
    },
    changeTab(tab) {
      this.records = tab === 'active' ? this.activeRecords : this.historyRecords;
    },
    toDevices() {
      this.pushMenu({ url: `/customer/customer/message/device` });
    },
    doTemplate() {
      let companyId = this.tab === 'active' ? this.activeCondition.companyId : this.historyCondition.companyId;
      this.pushMenu({ url: `/customer/customer/message/${companyId}/template` });
    },
  },
});
</script>
