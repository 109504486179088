<template>
  <!-- prettier-ignore -->
  <table class="table table-bordered table-hover table-striped text-center n-table">
    <thead>
    <tr>
      <th class="col-weight-10">{{ $t('label.id') }}</th>
      <th class="col-weight-10">{{ $t('label.customerId') }}</th>
      <th class="col-weight-10">{{ $t('label.accountId') }}</th>
      <th class="col-weight-10">{{ $t('label.type') }}</th>
      <th class="col-weight-10">{{ $t('label.status') }}</th>
      <th class="col-weight-33">{{ $t('label.token') }}</th>
      <th class="n-table-timestamp">{{ $t('label.messageTime') }}</th>
      <th v-if="isActive" v-table-action>{{ $t('label.action') }}</th>
      <th v-else class="action-detail">{{ $t('label.action') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="row in records" :key="row.id">
      <td>{{ row. id }}</td>
      <td class="n-ellipsis">
        <n-modal-link v-if="isActive" name="customer.profile" :value="row.customerId" />
        <span v-else>{{ row.customerId }}</span>
      </td>
      <td class="n-ellipsis">
        <n-modal-link v-if="isActive" name="customer.account" :value="row.accountId" />
        <span v-else>{{ row.accountId }}</span>
      </td>
      <td v-enums:CustomerMessageType="row.messageType"></td>
      <td v-enums:IssueStatus="row.messageStatus"></td>
      <td style="max-width:220px;" class="n-ellipsis" :title="row.messageToken">{{ row.messageToken }}</td>
      <td>{{ row.messageTime | datetime }}</td>
      <td class="n-button-box">
        <nb-custom text="detail" @on-click="openModal(row)" />
        <nb-confirm v-if="isActive" custom-icon="fas fa-sync" text="retry" :disabled="row.messageStatus !== $enums.IssueStatus.FAILURE" @on-ok="doRetry(row.id)" />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ModalCustomerMessage from './ModalCustomerMessage';
import { createModal } from '@/helpers/modal';
import { retry } from '@/api/customer/customer-customer-message';

export default {
  name: 'CustomerMessageTable',
  props: {
    isActive: Boolean,
    records: Array,
  },
  methods: {
    openModal(model = {}) {
      createModal(ModalCustomerMessage, { model, on: this });
    },
    doRetry(id) {
      return retry(id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  th.rw-action {
    width: 9rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>
