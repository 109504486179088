<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <ns-company :value="model.companyId" disabled />
        <n-field-text :value="model.deviceId" label="deviceId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.accountId" label="accountId" disabled />
        <n-field-id :value="model.sourceId" label="sourceId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.customerId" label="customerId" disabled />
        <n-field-enum :value="model.mobileType" enum-name="MobileType" label="mobileType" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-datetime :value="model.messageTime" label="messageTime" disabled />
        <n-field-datetime :value="model.deliverTime" label="deliverTime" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum :value="model.messageType" enum-name="CustomerMessageType" label="messageType" disabled />
        <n-field-enum :value="model.messageStatus" enum-name="IssueStatus" label="messageStatus" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.messageCookie" label="messageCookie" disabled />
        <n-field-text :value="model.messageResult" label="messageResult" disabled />
      </n-row-col2>
      <n-row-col1>
        <n-field-text :value="model.messageToken" label="messageToken" disabled />
      </n-row-col1>
      <n-row-col1>
        <n-field-text :value="model.messageContent" label="messageContent" disabled />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalCustomerMessage',
  components: { NModal },
});
</script>
