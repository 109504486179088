<template>
  <!-- prettier-ignore -->
  <div>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <n-field-id v-model="condition.accountId" label="accountId" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <customer-message-table :records="records"></customer-message-table>
    </n-table>
  </div>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import CustomerMessageTable from './CustomerCustomerMessageTable';
import { history } from '@/api/customer/customer-customer-message';

export default CustomerView.extend({
  name: 'CustomerCustomerMessageHistory',
  components: { CustomerMessageTable },
  mixins: [PaginateMixin],
  props: {
    condition: Object,
  },
  watch: {
    searched() {
      this.$emit('searchedHasError', this.searched && this.hasSearchError);
    },
  },
  methods: {
    doSearch(params) {
      let aid = this.condition.accountId;
      if (aid === null || aid === undefined || aid === '') {
        return Promise.resolve({ key: 'error.account_required', level: 'ERROR', type: 'MESSAGE' });
      } else {
        let p = { ...params, ...this.condition };
        return history(p);
      }
    },
    parse(data) {
      this.records = data.records;
      this.$emit('records', this.records);
    },
  },
});
</script>
